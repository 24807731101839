body {
  font-family: sans-serif;
  margin: 0;
  overflow: hidden;
}
iframe {
  border: 0;
  width: 100%;
  height: 100vh;
}
